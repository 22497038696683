import { useEffect } from "react"

const useOnEventOutside = (eventType, ref, handler, isEnabled = true) => {
  useEffect(
    () => {
      if (!isEnabled || !ref) {
        return () => {}
      }
      const listener = event => {
        if ([ref].flat().some(ref => ref.current?.contains(event.target))) {
          return
        }
        handler(event)
      }
      document.addEventListener(eventType, listener, true)
      return () => {
        document.removeEventListener(eventType, listener, true)
      }
    },

    // the handler is a new function on ever render. To prevent a re-run of the useEffect wrap it in a useCallback
    [eventType, ref, handler, isEnabled]
  )
}

export default useOnEventOutside
