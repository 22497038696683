import classNames from "classnames"
import React from "react"

const Overlay = ({ open, isOnPartnershipPage }) => {
  return (
    <div
      className={classNames("overlay", {
        "overlay--open": open,
        "overlay--partnership": isOnPartnershipPage,
      })}
    ></div>
  )
}

export default Overlay
