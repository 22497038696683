import React from "react"
import Header from "../templates/Header.js"
import Footer from "../templates/Footer.js"
import Helmet from "react-helmet"

export default function Layout({
  children,
  isOnPartnershipPage,
  restaurantsCollabs,
  hotelsCollabs,
  title,
  isMobile,
}) {
  const formattedTitle = title
    ? `Carolina Neto - ${title}`
    : "Carolina Neto - Digital And Lifestyle Content"

  const image =
    "https://images.ctfassets.net/ryerg55pdbdn/6Ax0ALSEQUlTdHHkWSMG6b/a3e60cc875953152f4d62812e8ade69f/puree2__2_.jpg"
  const description =
    "This is Carolina Neto website, where it is showcased all her professional partnerships."
  const host = "https://carolina-neto.com/"
  return (
    <>
      <Helmet htmlAttributes={{ lang: "pt" }}>
        <title>{formattedTitle}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={host} />
        <meta property="og:title" content={formattedTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta property="twitter:card" content={image} />
        <meta property="twitter:url" content={host} />
        <meta property="twitter:title" content={formattedTitle} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />

        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap"
        />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap"
        />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap"
        />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap"
        />
      </Helmet>

      <Header
        isOnPartnershipPage={isOnPartnershipPage}
        restaurantsCollabs={restaurantsCollabs}
        hotelsCollabs={hotelsCollabs}
        isMobile={isMobile}
      />
      {children}
      <Footer />
    </>
  )
}
