import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Waypoint } from "react-waypoint"
import classNames from "classnames"
import useOnEventOutside from "../utils/useOnEventOutside"
import Overlay from "../components/Overlay"

const Header = ({ hotelsCollabs, restaurantsCollabs, isOnPartnershipPage }) => {
  const [showRestaurantsMenu, setShowRestaurantsMenu] = useState(false)
  const [showHotelsMenu, setShowHotelsMenu] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const headerRef = useRef()
  const hotelsRef = useRef()
  const restaurantsRef = useRef()

  useOnEventOutside("click", hotelsRef, () => setShowHotelsMenu(false))
  useOnEventOutside("click", restaurantsRef, () =>
    setShowRestaurantsMenu(false)
  )

  return (
    <header>
      <Overlay
        isOnPartnershipPage={isOnPartnershipPage}
        open={showHotelsMenu || showRestaurantsMenu}
      ></Overlay>
      <nav ref={headerRef} className="header">
        <button type="button" className="header__mobile-toggler">
          <input
            type="checkbox"
            className="header__mobile-toggler__checkbox"
            id="mobile-toggler"
          />
          <label
            onClick={() => {
              setShowMobileMenu(!showMobileMenu)
            }}
            htmlFor="mobile-toggler"
            className="header__mobile-toggler__button"
          >
            <span className="header__mobile-toggler__icon">&nbsp;</span>
          </label>
        </button>
        <div
          className={classNames("header__brand", {
            "header__brand--opaque": showHotelsMenu || showRestaurantsMenu,
          })}
        >
          {isOnPartnershipPage ? (
            <Link to="/">
              <div className="header__brand__text">
                <span>C</span>
                <span className="header__brand__second-letter">N</span>
              </div>
            </Link>
          ) : (
            <a
              style={{ color: "white" }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" })
              }}
            >
              <div className="header__brand__text">
                <span>C</span>
                <span className="header__brand__second-letter">N</span>
              </div>
            </a>
          )}
        </div>
        <ul
          className={`${
            showMobileMenu
              ? "header__menu header__menu--mobile-showing"
              : "header__menu"
          }`}
        >
          {isOnPartnershipPage ? (
            <Link className="header__menu-item" to="/">
              Home
            </Link>
          ) : (
            <a
              style={{ color: "white" }}
              className="header__menu-item"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" })
              }}
            >
              Home
            </a>
          )}
          <li className="header__menu-item" ref={hotelsRef}>
            <button
              onClick={() => {
                !showHotelsMenu && setShowRestaurantsMenu(false)
                setShowHotelsMenu(!showHotelsMenu)
              }}
              className="header__menu-dropdown-toggler"
            >
              HOTELS
            </button>
            {showHotelsMenu && (
              <div className="header__menu-dropdown">
                {hotelsCollabs.map(collab => (
                  <Link
                    to={`/partnerships/${collab.fields.name
                      .replace(/[^a-zA-Z]/g, "-")
                      .toLowerCase()}`}
                    key={collab.fields.name
                      .replace(/[^a-zA-Z]/g, "-")
                      .toLowerCase()}
                    className="header__menu-dropdown-item"
                  >
                    {collab.fields.name}
                  </Link>
                ))}
              </div>
            )}
          </li>
          <li className="header__menu-item" ref={restaurantsRef}>
            <button
              onClick={() => {
                !showRestaurantsMenu && setShowHotelsMenu(false)
                setShowRestaurantsMenu(!showRestaurantsMenu)
              }}
              className="header__menu-dropdown-toggler"
            >
              RESTAURANTS
            </button>
            {showRestaurantsMenu && (
              <div className="header__menu-dropdown">
                {restaurantsCollabs.map(collab => (
                  <Link
                    className="header__menu-dropdown-item"
                    to={`/partnerships/${collab.fields.name
                      .replace(/[^a-zA-Z]/g, "-")
                      .toLowerCase()}`}
                    key={collab.fields.name
                      .replace(/[^a-zA-Z]/g, "-")
                      .toLowerCase()}
                  >
                    {collab.fields.name}
                  </Link>
                ))}
              </div>
            )}
          </li>
          {!isOnPartnershipPage && (
            <li className="header__menu-item">
              <a
                onClick={() => {
                  scrollTo("#about-me__anchor")

                  if (!document.getElementById("about-me__image")) {
                    setTimeout(() => {
                      scrollTo("#about-me__anchor")
                    }, 100)
                  }
                }}
              >
                ABOUT ME
              </a>
            </li>
          )}

          <li className="header__menu-item">
            <a
              onClick={() => {
                scrollTo("#contact")
                if (
                  !isOnPartnershipPage &&
                  !document.getElementById("about-me__image")
                ) {
                  setTimeout(() => {
                    scrollTo("#contact")
                  }, 100)
                  setTimeout(() => {
                    scrollTo("#contact")
                  }, 1000)
                }
              }}
            >
              CONTACT
            </a>
          </li>
        </ul>
      </nav>
      <Waypoint
        onEnter={() => headerRef.current.classList.remove("header--sticky")}
        onLeave={() => {
          if (!showMobileMenu) {
            headerRef.current.classList.add("header--sticky")
          }
        }}
      >
        <div className="header__waypoint"></div>
      </Waypoint>
    </header>
  )
}

export default Header
