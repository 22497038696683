import React from "react"

const Footer = () => (
  <>
    <footer className="footer">
      <div className="footer__row">
        <div className="footer__section">
          <div className="footer__brand">
            <span className="footer__first-letter">C</span>
            <span className="footer__second-letter">N</span>
          </div>
          <p className="footer__text">© 2020</p>
        </div>
        <div className="footer__section">
          <div className="row-hero__headline">Get in touch</div>
          <p className="footer__text">
            <a href="mailto:carolinacvneto@gmail.com">
              carolinacvneto@gmail.com
            </a>
          </p>
        </div>
        <div className="footer__section">
          <div className="row-hero__headline">Follow me</div>
          <div className="footer__icons">
            <a href="http://instagram.com/carolinavneto">
              <i className="icon-instagram"></i>
            </a>
            <a href="https://www.facebook.com/carolina.neto.16">
              <i className="icon-facebook"></i>
            </a>
          </div>
        </div>
        <div className="footer__section">
          <div className="row-hero__headline">Location</div>
          <p className="footer__text">Portugal</p>
        </div>
      </div>
    </footer>
    <div id="contact" />
  </>
)

export default Footer
